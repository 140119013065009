import Cookies from 'js-cookie'
import {defineStore, } from 'pinia'

export const useConsentStore = defineStore('consent-store', {
    state () {
        return {
            googleMaps: false,
            googleRecaptcha: false,
            spotify: false,
            twitch: false,
            youtube: false,
            showBanner: false,
        }
    },
    getters: {
        allAccepted: (state) => {
            if (state.googleMaps &&
                state.googleRecaptcha &&
                state.spotify &&
                state.twitch &&
                state.youtube
            ) {
                return true
            }
            return false
        },
    },
    actions: {
        setGoogleMaps (value: boolean, save = true) {
            this.googleMaps = value
            if (save) {
                this.save()
            }
        },
        setGoogleRecaptcha (value: boolean, save = true) {
            this.googleRecaptcha = value
            if (save) {
                this.save()
            }
        },
        setSpotify (value: boolean, save = true) {
            this.spotify = value
            if (save) {
                this.save()
            }
        },
        setTwitch (value: boolean, save = true) {
            this.twitch = value
            if (save) {
                this.save()
            }
        },
        setYoutube (value: boolean, save = true) {
            this.youtube = value
            if (save) {
                this.save()
            }
        },
        setAll (value: boolean, save = true) {
            this.googleMaps = value
            this.googleRecaptcha = value
            this.spotify = value
            this.twitch = value
            this.youtube = value
            if (save) {
                this.save()
            }
        },
        save () {
            let value = {
                googleMaps: this.googleMaps,
                googleRecaptcha: this.googleRecaptcha,
                spotify: this.spotify,
                twitch: this.twitch,
                youtube: this.youtube,
            }
            let stringValue = JSON.stringify(value)

            Cookies.set('IMMANUEL_CONSENT', stringValue, {expires: 365, })
        },
        load (data) {
            this.googleMaps = data.googleMaps || false
            this.googleRecaptcha = data.googleRecaptcha || false
            this.spotify = data.spotify || false
            this.twitch = data.twitch || false
            this.youtube = data.youtube || false
        },
    },
})
