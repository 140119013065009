<template>
    <div
        v-if="showBanner"
        class="fixed bottom-0 w-full bg-pink3/80 p-4 backdrop-blur"
        style="z-index: 999;"
    >
        <div class="container items-center gap-4 md:flex">
            <div class="flex items-center">
                <p>
                    <Cookie class="inline size-5" />
                    Wir oder Dritte speichern und verarbeiten deine personenbezogenen Informationen für folgende Zwecke:
                    Besucher-Statistiken und verschiedene Funktionalitäten.
                    <br>
                    <TransitionLink
                        :href="$route('vue_page.view', 'datenschutz')"
                        class="text-main1 underline hover:no-underline"
                    >
                        Weitere Informationen
                    </TransitionLink>
                </p>
            </div>
            <div class="mt-4 flex flex-col gap-2 md:mt-0 md:w-96">
                <Button
                    variant="outline-dark"
                    class="w-full"
                    @click="declineAll()"
                >
                    Ablehnen
                </Button>
                <Button
                    variant="outline-primary"
                    class="w-full"
                    @click="dialogStore.showDialog({}, 'cookie-consent')"
                >
                    Einstellen
                </Button>
                <Button
                    variant="primary"
                    class="w-full"
                    @click="acceptAll()"
                >
                    Akzeptieren
                </Button>
            </div>
        </div>
    </div>

    <CookieConsentDialog
        v-if="cookieConsentDialogVisible"
        undismissable
    />
</template>

<script setup lang="ts">
import Cookies from 'js-cookie'
import { Cookie,} from 'lucide-vue-next'
import {getActivePinia, storeToRefs,} from 'pinia'
import {getCurrentInstance, onMounted,} from 'vue'

import {useDialogStore,} from '@/js/components/advanced/dialog/dialog'
import {Button,} from '@/js/components/ui/button'
import {useConsentStore,} from '@/js/shared/consent/consent'
import CookieConsentDialog from '@/js/shared/consent/CookieConsentDialog.vue'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'

const consentStore = useConsentStore(getActivePinia())
let {showBanner,} = storeToRefs(consentStore)

const dialogStore = useDialogStore(getActivePinia())
const cookieConsentDialogVisible = dialogStore.isVisibleComputed('cookie-consent')

const {proxy:vm,} = getCurrentInstance()

onMounted(() => {
    let value = Cookies.get('IMMANUEL_CONSENT')
    if (value) {
        try {
            let cookieConsent = JSON.parse(value)
            consentStore.load(cookieConsent)
            consentStore.save()
        } catch(Exception) {
            showBanner.value = true
        }
    } else {
        showBanner.value = true
    }
})

const declineAll = () => {
    consentStore.setAll(false)
    showBanner.value = false
}

const acceptAll = () => {
    consentStore.setAll(true)
    showBanner.value = false
}
</script>
