import '@/js/shared/registerSw'

import {install as VueMonacoEditorPlugin, } from '@guolao/vue-monaco-editor'
import {createInertiaApp, router,} from '@inertiajs/vue3'
import {createHead, } from '@vueuse/head'
import {SchemaOrgUnheadPlugin, } from '@vueuse/schema-org'
import MasonryWall from '@yeger/vue-masonry-wall'
import {createPinia, } from 'pinia'
import {App, createApp, defineAsyncComponent, h, } from 'vue'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import VueMatomo from 'vue-matomo'

import {immanuelReverseUrl, } from '@/js/router'
import Layout from '@/js/shared/layout/Layout.vue'

async function resolvePageComponent (name, pages) {
    for (const page_path in pages) {
        if (name.split('/').every((namePart) => page_path.includes(namePart))) {
            let {default: page, } = await pages[page_path]()
            page.layout = Layout
            return typeof pages[page_path] === 'function' ? page : pages[page_path]
        }
    }

    throw new Error(`Page not found: ${name}`)
}

async function registerComponents (app: App) {
    const components = import.meta.glob([ '@/js/components/prose/**.vue', '@/js/components/mdc/**.vue', ])
    for (const path in components) {
        const componentName = path.split('/').pop()?.replace(/\.\w+$/, '') as string
        app.component(componentName, defineAsyncComponent(components[path]))
    }
}

const routePlugin = {
    install: (app: App, _options: Record<string, unknown>) => {
        app.config.globalProperties.$route = immanuelReverseUrl
    },
}

createInertiaApp({
    resolve: (name) => resolvePageComponent(name, import.meta.glob([ './modules/**/*Page.vue', ])),
    setup ({el, App, props, plugin, }) {
        const pinia = createPinia()
        const head = createHead()
        head.use(SchemaOrgUnheadPlugin({
            host: 'https://immanuel-wetzlar.de',
        }, () => {
            return {
                path: location.pathname,
            }
        }))
        let app = createApp({render: () => h(App, props), })
            .use(plugin)
            .use(routePlugin, {})
            .use(pinia)
            .use(head)
            .use(MasonryWall)
            .use(VueMonacoEditorPlugin, {
                paths: {
                    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
                },
            })
            .use(VueGoogleMaps, {
                load: {
                    key: 'AIzaSyARV0Kwb1fDF3B_HREbKVqf0ikeiPB5yxE',
                },
            })
            .use(VueMatomo, {
                host: 'https://matomo.immanuel-wetzlar.de/',
                siteId: 1,
            })

        router.on('navigate', (event) => {
            window._paq.push([ 'trackPageView', ])

            if(event.detail.page.props.currentUser) {
                window._paq.push([ 'setUserId', event.detail.page.props.currentUser.id, ])
            }
        })

        registerComponents(app)

        app.mount(el)
    },
    progress: {
        delay: 250,
        color: '#294c7e',
    },
})
