<template>
    <div v-cloak>
        <DebugNotification v-if="!$page.props.isProduction" />
        <NavigationLoggedIn v-if="$page.props.loggedIn && pageType != 'kiosk'" />
        <TwitchNotification v-if="pageType == '' && $page.props.youtube_live && $page.props.youtube_live.length" />
        <GlobalNotice
            v-if="pageType == '' && $page.props.generalNotice && $page.props.generalNotice.enable"
            :notice="$page.props.generalNotice"
        />
        <Navigation v-if="pageType == ''" />

        <slot />

        <Footer v-if="pageType == '' || pageType == 'custom'" />
        <Alert v-if="pageType != 'kiosk'" />
        <GlobalAudioPlayer v-if="loaded && pageType == ''" />
        <CookieConsent v-if="loadCookieConsent && pageType == ''" />
    </div>
</template>

<script setup lang="ts">
import '@/js/style.css'

import {router,} from '@inertiajs/vue3'
import {defineSearchAction,  defineWebSite, } from '@unhead/schema-org-vue'
import {useHead,} from '@unhead/vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {getCurrentInstance, onMounted, ref, watch, } from 'vue'

import {useDialogStore,} from '@/js/components/advanced/dialog/dialog'
import {useUserStateSync,} from '@/js/modules/auth/userStateSync'
import GlobalNotice from '@/js/modules/main/content/GlobalNotice.vue'
import Alert from '@/js/shared/alert/Alert.vue'
import CookieConsent from '@/js/shared/consent/CookieConsent.vue'
import DebugNotification from '@/js/shared/layout/DebugNotification.vue'
import Footer from '@/js/shared/layout/Footer.vue'
import Navigation from '@/js/shared/layout/Navigation.vue'
import NavigationLoggedIn from '@/js/shared/layout/NavigationLoggedIn.vue'
import TwitchNotification from '@/js/shared/layout/TwitchNotification.vue'
import {useGlobalAudioPlayer,} from '@/js/shared/media/audio'
import GlobalAudioPlayer from '@/js/shared/media/GlobalAudioPlayer.vue'

const isMounted = ref(false)
const pageType = ref('')

onMounted(() => {
    let splash = document.getElementById('splash-screen')
    splash.classList.add('hidden')

    isMounted.value = true
})

let loadCookieConsent = ref(import.meta.env.VITE_APP_ENV === 'production' || true)

router.on('exception', (event) => {
    event.preventDefault()
    console.log('inertia exception')
    console.error(event)
    if (import.meta.env.VITE_APP_ENV === 'production' && event.detail.exception.message.startsWith('Failed to fetch dynamically imported module')) {
        // vite reloaded on the server, do a full reload
        console.log('reloading page...')
        location.reload()
    }
})

const audioStore = useGlobalAudioPlayer(getActivePinia())
let {loaded,} = storeToRefs(audioStore)

useUserStateSync()

useHead({
    meta: [
        {
            name: 'description',
            content: 'Wir wollen Gott verherrlichen, indem wir die Freude an Jesus fördern und verbreiten.',
        },
    ],
    script: [
        {
            type: 'application/ld+json',
            id: 'schema-org-graph',
            key: 'schema-org-graph',
            // @ts-expect-error runtime type
            nodes: [
                defineWebSite({
                    name: 'Immanuel-Gemeinde Wetzlar ',
                    potentialAction: [
                        defineSearchAction({
                            target: '//suche/?q={search_term_string}',
                        }),
                    ],
                }),
            ],
        },
    ],
})

const {proxy: vm, } = getCurrentInstance()
const dialogStore = useDialogStore(getActivePinia())

vm.$page && watch(() => vm.$page, () => {
    dialogStore.closeDialog()

    if (vm.$page?.component?.startsWith('Admin')) {
        pageType.value = 'admin'
    } else if (vm.$page?.component?.startsWith('Kiosk')) {
        pageType.value = 'kiosk'
    } else if (vm.$page?.component?.startsWith('Custom')) {
        pageType.value = 'custom'
    } else {
        pageType.value = ''
    }
}, {immediate: true,})
</script>
